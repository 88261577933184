@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;600;800&display=swap');

.App {
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  background-color: #1a2330;
}

h2, h3 {
  margin: 0;
}
